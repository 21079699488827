import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = ({ ...props }) => (
    <Layout title="Seite nicht gefunden" noSlider {...props}>
        <p>Diese Seite wurde nicht gefunden.</p>
    </Layout>
);

export default NotFoundPage;
